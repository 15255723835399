<template>
  <div class="col-12 col-lg-12" v-if="info.text_seats">
    <div class="row mt-4">
      <div class="col-12 col-lg-6 spacing-pricing">
        <div class="price_container selected_seats mb-5">
          <div class="row">
            <div class="col-12 col-lg-4 vert_mid">
              <span class="text">{{ $t('tickets') }}</span>
            </div>
            <div class="col-12 col-lg-8">
              <span class="info" v-html="info.text_seats"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 spacing-pricing">
        <div class="price_container selected_seats mb-5">
          <div class="row">
            <div class="col-12 col-lg-8">
              <span class="text">{{ $t('Price') }}</span>

            </div>
            <div class="col-12 col-lg-4">

              <span class="price">{{ info.price }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-12" v-if="info.text_entrances">
    <div class="row mt-4">
      <div class="col-12 col-lg-6 spacing-pricing">
        <div class="price_container selected_seats mb-5">
          <div class="row">
            <div class="col-12 col-lg-4 vert_mid">
              <span class="text">{{ $t('entrance') }}</span>
            </div>
            <div class="col-12 col-lg-8">
              <span class="info" v-html="info.text_entrances"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6 spacing-pricing">
        <div class="price_container selected_seats mb-5">
          <div class="row">
            <div class="col-12 col-lg-8">
              <span class="text">{{ $t('Price') }}</span>

            </div>
            <div class="col-12 col-lg-4">

              <span class="price">{{ info.price_entrances }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-8 mid_col mt-4" v-if="info.all_price && is_show">

        <div class="price_container selected_seats mb-5">
          <div class="row">
            <div class="col-12 col-lg-8">
              <span class="text">{{ $t('total_price') }}</span>

            </div>
            <div class="col-12 col-lg-4">

              <span class="price">{{ info.all_price }}</span>
            </div>
          </div>
        </div>
  </div>

</template>

<script>
export default {
  name: "eventTotalPrice",
  props: {
    info: Object,
    is_show:Boolean
  },
}
</script>

<style scoped>

</style>