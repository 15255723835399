
import { defineComponent } from "vue";

export default defineComponent({
  name: "drawSelectedSeatsCheckout",
  emits: ["deleteSeat"],
  props: {
    seat: Object,
    index: Number,
  },
  data() {
    return {
      site_url: process.env.VUE_APP_BACKEND_URL
    }
  },
  methods: {
    deleteSeat(id){
      this.$emit('deleteSeat', id)
    }

  },
  components: {},

});
