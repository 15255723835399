
import {defineComponent, reactive, ref, toRefs} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "sign-up-modal",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  emits: ['submitted','loginFormTriggered'],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    // const emitSubmission = () =>{
    //   this.$emit('submitted',true)
    // }
    const submitButton = ref<HTMLButtonElement | null>(null);
    const state = reactive({
      date_of_birth: '',
    })
    const registration = Yup.object().shape({
      first_name: Yup.string().required(`${t('is_required')}`).label(`${t('first_name')}`),
      last_name: Yup.string().required(`${t('is_required')}`).label(`${t('last_name')}`),
      email: Yup.string().min(4).required(`${t('is_required')}`).email().label(`${t('email')}`),
      phone: Yup.string().min(8).required(`${t('is_required')}`).label(`${t('phone')}`),
      password: Yup.string().min(4).required(`${t('is_required')}`).label(`${t('Password')}`),
      password_confirmation: Yup.string()
          .min(4)
          .required(`${t('is_required')}`)
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .label("Password Confirmation"),
    });

    let onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      values.date_of_birth = state.date_of_birth;
      // Dummy delay

        // Send login request

        store
            .dispatch(Actions.REGISTER, values)
            .then(() => {

              Swal.fire({
                text: "You have successfully created new account!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              })
            })
            .catch(() => {
              const error = Object.values(store.getters.getErrors.error);
              Swal.fire({
                html: error.map(item => `<p>${item}</p>`)
                    .join(''),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
      context.emit('submitted', {ft: 5})

      submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      ...toRefs(state),
    };
  },
  methods: {
    submitEvent() {
      this.$emit('submitted', {ft: 5})
    },
    emitLoginModal() {
      this.$emit('loginFormTriggered', true)
    },
  }
});
