
import {computed, defineComponent, onMounted, ref, toRaw} from "vue";
import {useRoute, useRouter} from "vue-router";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import store from "@/store";
import JwtService from "@/core/services/JwtService";
import {Actions} from "@/store/enums/StoreEnums";
import router from "@/router/router";
import {MenuComponent, ScrollComponent} from "@/assets/ts/components";
import {ElLoading} from "element-plus";
import {ErrorMessage, Field, Form} from "vee-validate";
import moment from "moment";
import SeatElement from "@/components/events/SeatElement.vue";
import RegisterModal from "@/components/events/RegisterModal.vue";
import eventTotalPrice from "@/components/events/eventTotalPrice.vue";
import LoginModal from "@/components/events/LoginModal.vue";
import DrawSelectedSeatsCheckout from "@/components/events/DrawSelectedSeatsCheckout.vue";
import StarDetailModal from "@/components/events/StarDetailModal.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import $ from "jquery";
import {useI18n} from "vue-i18n";
import { Head } from '@vueuse/head'
import axios from "axios";

export default defineComponent({
  name: "event",
  components: {
    SeatElement,
    RegisterModal,
    eventTotalPrice,
    LoginModal,
    DrawSelectedSeatsCheckout,
    Field,
    Form,
    ErrorMessage,
    StarDetailModal,
    Head
  },
  data() {
    const { t } = useI18n();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(255, 255, 255, 0.7)',
    })
    const registration = Yup.object().shape({
      first_name: Yup.string().required(`${t('is_required')}`).label(`${t('first_name')}`),
      last_name: Yup.string().required(`${t('is_required')}`).label(`${t('last_name')}`),
      email: Yup.string().min(4).required(`${t('is_required')}`).email().label(`${t('email')}`),
      phone: Yup.string().min(8).required(`${t('is_required')}`).label(`${t('phone')}`),
      address: Yup.string().min(8).required(`${t('is_required')}`).nullable().label(`${t('address')}`),
    });
    const telCellForm = ref('')
    const isButtonDisabled = ref(false);

    const disableButton = () => {
      isButtonDisabled.value = true;
    };

    const onSubmitCheckout = (values) => {
      disableButton();
      const type = this.payment_method as any;
      let payload = {
        user: values,
        project_event_id: this.$route.params.id,
        event_seats: this.selected_seats,
        event_date_id: this.selected_date_id,
        payment_method: this.payment_method,
        seat_description: this.seat_pricing_info,
        entrances: this.entrances_count,
        bonus:this.handleBonusChange
      }
      // Dummy delay
      setTimeout(() => {
        // Send login request
        store.dispatch(Actions.PURCHASE, payload)
            .then(response => {
              if(type === 'telcell'){
                const data = JSON.parse(response.redirectURL);
                document.forms['telcell_form'].elements['action'].value = data.inputs.action;
                document.forms['telcell_form'].elements['issuer'].value = data.inputs.issuer;
                document.forms['telcell_form'].elements['currency'].value = data.inputs.currency;
                document.forms['telcell_form'].elements['price'].value = data.inputs.price;
                document.forms['telcell_form'].elements['product'].value = data.inputs.product;
                document.forms['telcell_form'].elements['issuer_id'].value = data.inputs.issuer_id;
                document.forms['telcell_form'].elements['valid_days'].value = data.inputs.valid_days;
                document.forms['telcell_form'].elements['lang'].value = data.inputs.lang;
                document.forms['telcell_form'].elements['security_code'].value = data.inputs.security_code;
                document.forms['telcell_form'].submit();
              }else{
                if (response && response.success == true) {
                  if (!response.redirectURL) {
                    Swal.fire({
                      text: response.message,
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: "Ok, got it!",
                      customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                      },
                    }).then(function () {
                      window.location.reload();
                      //router.push({ name: "dashboard" });
                    });
                  }
                  if (response.redirectURL) {
                    window.location.replace(response.redirectURL);
                  }
                }

              }
            })
            .catch(() => {
              const error = Object.values(store.getters.getErrors.error);
              let message = error.map(item => `<p>${item}</p>`).join('');

              Swal.fire({
                html: message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
      }, 1000);
    };
    const pinchOptions = { maxZoom: 5,pinchSpeed: 0.6,zoomSpeed: 0.088, bounds: true, boundsPadding: 0.1,transformOrigin: {x: 0.5, y: 0.5},zoomDoubleClickSpeed: 1,smoothScroll: false}
    return {
      loading,
      load_placeholder: true,
      event: {},
      site_url: process.env.VUE_APP_BACKEND_URL,
      site_name: process.env.VUE_APP_NAME,
      selected_date_id: '',
      map_or_entrance_data: {},
      selected_seats: [] as any,
      seat_pricing_info: {},
      payment_method: "",
      registration,
      submitButton,
      onSubmitCheckout,
      isInZoom: false,
      entrances_count: {},
      currentUser: {},
      is_auth: store.getters.isUserAuthenticated,
      bonusPoints: 0,
      handleBonusChange: 0,
      isWishlist: false,
      pinchOptions: pinchOptions,
      current_url:window.location,
      lng: localStorage.getItem("lang"),
      clickedStar: {},
      map_is_loading: true,
      checkoutClick:false,
      isButtonDisabled,
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n();
    if (route.query.paymentCode) {
      let code = route.query.paymentCode;
      if (Number(code) === 0) {
        Swal.fire({
          text: t('payment_successful'),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          router.replace({query: {}})

          //router.push({ name: "dashboard" });
        });
      }
      if (Number(code) > 0) {
        Swal.fire({
          text: t('payment_declined'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          router.replace({query: {}})

          //router.push({ name: "dashboard" });
        });
      }
    }
    return {
      checkoutModal: ref(false),
      registerModal: ref(false),
      loginModal: ref(false),

    }
  },

  methods: {
    getEvent() {
      const id = this.$route.params.id;
      store.dispatch(Actions.GET_EVENT_BY_ID, id).then(response => {
        this.event = response.data;

        this.loading.close();
        this.load_placeholder = false;

        if(response.data.isWishlist) {
          this.isWishlist = response.data.isWishlist
        }
        if(response.data.dates.length == 1 ){
          if(response.data.dates[0].id) {
            this.setCurrentSlide(response.data.dates[0].id)
          }
        }
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });
    },
    extractDate: function (value) {
      return moment(value).format('DD-MM-YYYY');
    },
    extractTime: function (value) {
      return moment(value).format('HH:mm');
    },
    dateTime: function (value,format) {
      let lang:any = this.lng;
        if(lang == 'am') {
          lang = 'hy-am'
        }
      return moment(value).lang(lang).format(format);
    },
    setCurrentSlide(value) {
      const allDates = document.querySelectorAll('.carousel__item');
      allDates.forEach(element => {
        element.classList.remove("date_selected");
      });
      const selectedSlide = document.querySelector('#event_date_' + value);
      if (selectedSlide) {
        selectedSlide.classList.add('date_selected');
      }
      this.selected_date_id = value;
      this.map_is_loading = true;

      this.getEventMapOrEntrance();
      // console.log(value,'slideeenu')
    },
    getEventMapOrEntrance() {
      const payload = {
        event_date_id: this.selected_date_id,
        project_event_id: this.$route.params.id
      }
      store.dispatch(Actions.GET_EVENT_MAP_OR_ENTRANCE, payload).then(response => {
        this.map_or_entrance_data = response;
        this.map_is_loading = false;
        if(response.map && response.map.width > 1500) {
          this.pinchOptions = { maxZoom: 10,pinchSpeed: 0.6,zoomSpeed: 0.088,bounds: true, boundsPadding: 0.000005,transformOrigin: {x: 0.5, y: 0.5},zoomDoubleClickSpeed: 1,smoothScroll: false}
        }
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });

    },
    getSeatInfo(seat) {
      if (seat) {
        let title = '';
        if (seat.price) {
          title += this.$t('Price') + ': ' + seat.price + ' | ';
        }
        if (seat.seat.row) {
          title += this.$t('Row') + ': ' + seat.seat.row + ' | ';
        }
        if (seat.seat.column) {
          title += this.$t('Column') + ': ' + seat.seat.column + ' | ';
        }
        if (seat.seat.name) {
          title += this.$t('ArenaName') + ': ' + seat.seat.name;
        }

        return title;
      }
    },
    selectSeat(id) {

      if (!this.isInZoom) {
        let elem = $('#seat_nm_' + id);
        if (elem.hasClass('selected_seat')) {
          elem.removeClass('selected_seat')
          this.arrayRemove(id)
        } else {
          elem.addClass('selected_seat')
          this.selected_seats.push(id);
        }
        this.getTotal()

        //console.log(this.$refs['seat_nm_'+id].$el.classList,'iddsss');
      } else {
        return false;
      }
    },
    getTotal() {
      const payload = {
        elements: this.selected_seats,
        entrances: this.entrances_count
      }


      store.dispatch(Actions.GET_SEATS_PRICES, payload).then(response => {
        this.seat_pricing_info = response.data;
        //console.log(response,'responseee_dataaaaa')

      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });
    },
    arrayRemove(value) {
      let arrayIndex = this.selected_seats.indexOf(value);
      this.selected_seats.splice(arrayIndex, 1); // colors = ["red","blue","green"]
    },
    is_soon(event) {
      if (event && event.is_soon && event.is_soon === "no") {
        return false
      } else {
        return true;
      }
    },
    eventIsFree(event) {
      if (event && event.mutqavchar_type && event.mutqavchar_type === "free") {
        return true
      } else {
        return false;
      }
    },
    checkoutClicked(){
      this.checkoutClick = true;
    },
    toggleModal() {
      // if(store.getters.isUserAuthenticated) {
      //   this.checkoutModal = true;
      // }else {
      //   this.registerModal = true;
      // }
      $('#checkoutModal').modal('toggle')
      this.checkoutModal = true;
    },
    RegistrationSubmitted(e) {
      return this.registerModal = false;
    },
    loginFormTriggered(e) {
      if (e == true) {
        this.registerModal = false;
        this.loginModal = true;
      }
    },
    registerFormTriggered(e) {
      if (e == true) {
        this.loginModal = false;
        this.registerModal = true;
      }
    },
    LoginSubmitted(e) {
      return this.loginModal = false;
    },
    setPaymentMethod(method) {
      this.payment_method = method;
    },
    onPanStart() {
      let seats_container :any = this.$refs['seats_container'];
      if(seats_container) {
        seats_container.classList.add('hide')
      }
      this.isInZoom = true;
      setTimeout(() => {
        this.isInZoom = false;
      }, 200);
    },
    onPanEnd() {
      let seats_container :any = this.$refs['seats_container'];
      if(seats_container) {
        seats_container.classList.remove('hide')
      }
      setTimeout(() => {
        this.isInZoom = false;
      }, 200);
    },
    entranceChanged() {
      console.log(this.entrances_count,'123');
      this.getTotal()
    },
    getCurrentUser() {
      if (store.getters.isUserAuthenticated) {

        store.dispatch(Actions.GET_USER).then(res => {
          this.currentUser = res.data;
          this.bonusPoints = res.data.bonus;
        })
      }
    },
    calculateBonus(total,bonus){
      if(bonus <= this.bonusPoints) {
        return Number(total.match(/\d+/)[0]) - Number(bonus);
      }else {
        return total
      }
    },
    toggleWishlist(eventId) {
      if (store.getters.isUserAuthenticated) {

        const payload = {
          project_event_id:eventId
        }
        store.dispatch(Actions.SET_USER_WISHLIST,payload).then(res => {
          console.log(res)
          if(res.status == "yes") {
            this.isWishlist = true
          }
          if(res.status == "no") {
            this.isWishlist = false
          }
          //this.currentUser = res.data;
          //this.bonusPoints = res.data.bonus;
        })
      }
    },
    openStarInfo(info){
      this.clickedStar = info;
      //startCardModal
//      $('#startCardModal').modal('show');
    }
  },


  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.getEvent();
        this.getCurrentUser();
      }
    }

  },

});
