
import { defineComponent } from "vue";

export default defineComponent({
  name: "event-seat-element",
  props: {
    seat: Object,
    index: Number,
  },
  data() {
    return {
      site_url: process.env.VUE_APP_BACKEND_URL
    }
  },
  methods: {
    getSeatColor(){
      if(this.seat && this.seat.color){
        return this.seat.color
      }else {
        return '#9C9C9C'
      }
    },

  },
  components: {},

});
