
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import UrlHelper from "@/core/helpers/UrlHelper";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LoginModal",
  emits: ['submitted','registerFormTriggered'],
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required(`${t('is_required')}`).label(`${t('email')}`),
      password: Yup.string().min(4).required(`${t('is_required')}`).label(`${t('Password')}`),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors

      values.domain = UrlHelper.getRequestHostname();

      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
            .dispatch(Actions.LOGIN, values)
            .then(() => {
              // Swal.fire({
              //   text: "You have successfully logged in!",
              //   icon: "success",
              //   buttonsStyling: false,
              //   confirmButtonText: "Ok, got it!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-primary",
              //   },
              // }).then(function () {
              //   // Go to page after successfully login
              //   router.push({ name: "dashboard" });
              // });

            })
            .catch(() => {
              const [message] = Object.keys(store.getters.getErrors);
              Swal.fire({
                text: store.getters.getErrors.error_message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        context.emit('submitted', {from: 'login'})
        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      t
    };
  },
  methods: {
    submitEvent() {
      this.$emit('submitted', {from: 'login'})
    },
    emitRegisterModal() {
      this.$emit('registerFormTriggered', true)
    },
  }
});

