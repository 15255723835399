
import { defineComponent, ref } from "vue";


export default defineComponent({
  name: "LoginModal",
  emits: ['submitted','registerFormTriggered'],
  props: {
    star: Object,
    lng: String
  },
data() {
  return {
    site_url: process.env.VUE_APP_BACKEND_URL,
  }
}

});

